import React, { Component } from 'react'; 
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { API_BASE_URL } from '../config';
import PersonIcon from '@mui/icons-material/Person';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { saveAs } from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

class DownloadsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            list: [],
            downloading: false,
        }

    };

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        if (!token) {
            throw new Error("Not Authenticated");
        }
        const request = new Request(`${API_BASE_URL}/HEXSelector/Downloads`, {
            method: 'GET',
            headers: new Headers({ Authorization: `Bearer ${token}`, Accept: 'application/json' }),
        });
        fetch(request)
        .then(response => response.json())
        .then(data => this.setState({ fetched: true, list: data }))
        .catch(err => this.setState({ fetched: false, list: [] }));
    }

    downloadFile(item) {
        const filename = item.fileName;
        const token = sessionStorage.getItem('token');
        console.log(item);
        if (!token) {
            throw new Error("Not Authenticated");
        }

        const request = new Request(`${API_BASE_URL}/HEXSelector/Downloads/${item.id}`, {
            method: 'GET',
            headers: new Headers({ Authorization: `Bearer ${token}`, Accept: 'application/octet-stream' }),
        });
        item.downloading = true;
        this.setState({ downloading : true});
        fetch(request)
        .then(response => {
            //return response.blob();
            const reader = response.body.getReader();
            item.total = +response.headers.get('content-length');
            item.recv = 0;
            let reading = true;
            let chunks = [];

            while (reading) {
                const { done, value } = reader.read();
                reading = !done;
                if (!done) {
                    chunks.push(value);
                    item.recv += 1;
                }
                this.setState({ downloading: !done });
            }
            let blob = new Blob(chunks);
            return blob;
        })
        .then(data => {
            this.setState({ downloading: false });
            saveAs(data, filename);
        });
    }

    render() {
        let installs = [];
        this.state.list.forEach(x => { 
            if (x.type === "Install") {
                x.recv = 0;
                x.total = 0;
                installs.push(x);
            }
        });
        let guides = [];
        this.state.list.forEach(x => {
            if (x.type === "Guide") {
                x.recv = 0;
                x.total = 0;
                guides.push(x);
            }
        });
        return (
            <Box
                sx={{
                    // display: 'flex',
                    // flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        p: 2,
                    },
                }}>
                <Paper elevation={1}>
                    <h2>Danfoss HEXSelector Downloads</h2>
                </Paper>
                {installs.length > 0 ?
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}>
                        Installer files
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper elevation={3}>
                            <List>
                                {installs.map(item => {
                                    return (
                                        <ListItem key={item.id}>
                                            <ListItemButton>
                                                <ListItemIcon>{item.icon === "PersonIcon" ? <PersonIcon /> : <GroupAddIcon />}
                                                </ListItemIcon>
                                                <a href={`/${item.fileName}`}>{item.description}</a>
                                            </ListItemButton>
                                            {/* <ListItemButton onClick={e => this.downloadFile(item)} >
                                                <ListItemIcon>{item.icon === "PersonIcon" ? <PersonIcon /> : <GroupAddIcon />}
                                                </ListItemIcon>
                                                <ListItemText primary={item.description} />
                                                {(this.state.downloading && item.downloading) ? `${item.recv} / ${item.total}` : ''}
                                            </ListItemButton> */}
                                        </ListItem>
                                    )})}
                            </List>
                        </Paper>
                    </AccordionDetails>
                </Accordion> : ''}
                {guides.length > 0 ?
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}>
                        Danfoss HEXSelector Guides
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper elevation={3}>
                            <List>
                                {guides.map(item => {
                                    return (
                                        <ListItem key={item.id}>
                                            <ListItemButton onClick={e => this.downloadFile(item)}>
                                                <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
                                                <ListItemText primary={item.description} />
                                                {(this.state.downloading && item.downloading) ? <CircularProgress /> : ''}
                                            </ListItemButton>
                                        </ListItem>
                                    )})}
                            </List>
                        </Paper>
                    </AccordionDetails>
                </Accordion> : ''}
            </Box>
        );
    }
}

export default DownloadsList;