import React, { Fragment } from 'react';
import logo from './Engineering_Tomorrow_BackGround.jpg';
import './App.css';
import Login from './components/login'
import useToken from './components/useToken';
import DownloadsList from './components/downloads';

const guideUrl = `HEXSelector Registration Guide.pdf`;

function App() {
  const { token, setToken } = useToken();

  return <Fragment>
    <div className="App">
      <header className="App-header">
        <a href="https://danfoss.com"><img src={logo} alt="logo" /></a>
        {token ? <DownloadsList /> : <Login setToken={setToken} />}
        <span>
          <a href="https://www.danfoss.com/en/terms/privacy/" target='_blank' rel='noreferrer'>Danfoss Privacy Policy</a>
          <span> | </span>
          <a href="https://hexacthost.danfoss.com/hexselector-terms-2023-05-09.html" target='_blank' rel='noreferrer'>HEXSelector Terms &amp; Conditions</a>
          <span> | </span>
          <a href={guideUrl} target='_blank' rel='noreferrer'>HEXSelector Registration Guide (PDF)</a>
        </span>
     </header>
    </div>
  </Fragment>
}

export default App;
